@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  font-size: 16px;
} */

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */
/* 
body {
  font-size: 20px
} */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .px-page-xl {
    padding-left: max(calc((100% - 1000px) / 2), 1rem);
    padding-right: max(calc((100% - 1000px) / 2), 1rem);
  }

  .mx-page-xl {
    margin-left: max(calc((100% - 1000px) / 2), 1rem);
    margin-right: max(calc((100% - 1000px) / 2), 1rem);
  }

  .debug {
    @apply border border-red-500;
  }
}

.truncate-3line {
  /* Ensure text does not wrap */
  overflow: hidden;
  /* Set maximum number of lines */
  max-height: calc(
    3.6em + 1rem
  ); /* Adjust based on your font-size and line-height */
  /* Ensure text truncates with ellipsis */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
}

.truncate-3line::after {
  /* Ellipsis styling */
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: transparent;
  padding-right: 0.5em;
  visibility: hidden;
}

.truncate-3line:not(:hover)::after {
  /* Show ellipsis only if text is truncated */
  visibility: visible;
}

.truncate-6line {
  /* Ensure text does not wrap */
  overflow: hidden;
  /* Set maximum number of lines */
  max-height: 195px; /* Adjust based on your font-size and line-height */
  /* Ensure text truncates with ellipsis */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  position: relative;
}

.truncate-6line::after {
  /* Ellipsis styling */
  content: "";
  /* Positioning */
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: transparent;
  padding-right: 0.5em;
  visibility: hidden;
}

.truncate-6line:not(:hover)::after {
  /* Show ellipsis only if text is truncated */
  visibility: visible;
}
